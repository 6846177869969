import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject, BehaviorSubject, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private subject = new Subject<any>();
  loader = false;
  private keepAfterNavigationChange = false;
  public languageData: Subject<string> = new BehaviorSubject<any>({});
  public campaignId: Subject<any> = new BehaviorSubject<any>(0);
  public componentName: Subject<any> = new BehaviorSubject<any>('');

  constructor(private router: Router, private snackBar: MatSnackBar) {
    // clear alert message on router change
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        }
      }
    });
  }

  success(message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;

    this.subject.next({ type: 'success', text: message });

    this.removeMessage();
  }

  error(message: string, keepAfterNavigationChange = false) {

    this.keepAfterNavigationChange = keepAfterNavigationChange;

    this.subject.next({ type: 'error', text: message });

    this.removeMessage();
  }

  warning(message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;

    this.subject.next({ type: 'warning', text: message });

    this.removeMessage();
  }

  info(message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;

    this.subject.next({ type: 'info', text: message });

    this.removeMessage();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  getLoader(): Observable<any> {
    return of(this.loader);
  }

  removeMessage() {
    setTimeout(() => this.subject.next({}), 6000);
  }
}
