import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ConstantService, ApiService, HelperService, AuthService } from '@app/core';
import * as moment from 'moment';
declare var $: any;
@Component({
  selector: 'app-sales-table',
  templateUrl: './sales-table.component.html',
})
export class SalesTableComponent implements OnInit, AfterViewInit {
  @Input() data: any;

  salesRecords: any = [];
  commissionHistory: any = [];
  previousMonthsCommission = 0;
  userIDMSID: any;
  userIDMSIDNEW: any;
  role: any;
  isIndividualSale = true;
  public currentMonth: string;
  public currentYear: string;
  public salesTablePath: string;

  years: any = [];
  months: any = [];

  filterOptions = {
    month: '',
    year: ''
  };
  oTable: any;
  paidCommission: any;
  constructor(
    private apiSvc: ApiService,
    private constantSvc: ConstantService,
    private helperSvc: HelperService,
    private authenticationService: AuthService,
  ) { }

  ngOnInit() {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      this.userIDMSID = currentUser.userData.idms_id;
      this.userIDMSIDNEW = currentUser.userData.idms_id_new;
      this.role = currentUser.userData.role_id;

      if (this.role === '1') {
        this.isIndividualSale = false;
        this.salesTablePath = '/sales';
      } else {
        this.salesTablePath = '/deals';
      }
    }

    this.years = this.helperSvc.getYears();
    this.months = this.helperSvc.getMonths();
    this.currentYear = (moment().year().toString());
    this.currentMonth = ('0' + (moment().month() + 1)).slice(-2).toString();
    this.filterOptions.year = this.currentYear;
    this.filterOptions.month = this.currentMonth;
  }

  ngAfterViewInit() {
    this.loadSales();
    if (this.role !== '1') {
      this.getSalesCommissionHistory();
      this.getPreviousMonthsRemainingCommission();
    }
  }

  loadSales() {
    this.apiSvc.getService(this.constantSvc.APIConfig.GETLATESTSALES, '?user_id=' + this.userIDMSID+','+this.userIDMSIDNEW + '&month=' + this.filterOptions.month + '&year=' + this.filterOptions.year + '&isIndividualSales=' + this.isIndividualSale).subscribe(
      res => {
        if (res.Status === 200) {
          this.salesRecords = res.Data;
          this.initTable();
        } else {
          this.salesRecords = [];
        }
      }, err => { }
    );
  }

  getSalesCommissionHistory() {
    this.apiSvc.getService(this.constantSvc.APIConfig.GETCOMMISSION, '?user_id=' + this.userIDMSID + '&month=' + this.filterOptions.month + '&year=' + this.filterOptions.year).subscribe(
      res => {
        if (res.Status === 200) {
          this.commissionHistory = res.Data;
          this.paidCommission = this.commissionHistory.map((t:any) => t.amount).reduce((acc:any, value:any) => acc + Number(value), 0);
        } else {
          this.commissionHistory = [];
        }
      }, err => console.log('error in getting sales commission ', err)
    );
  }

  getPreviousMonthsRemainingCommission() {
    this.apiSvc.getService(this.constantSvc.APIConfig.GETPREVIOUSMONTHSREMAININGCOMM, '?user_id=' + this.userIDMSID).subscribe(
      res => {
        if (res.Status === 200) {
          this.previousMonthsCommission = res.commissionAmount;
        } else {
          this.previousMonthsCommission = 0;
        }
      }, err => console.log('error in getting sales commission ', err)
    );
  }


  getTotalCommission() {
    return this.salesRecords.map((t:any) => t.commissionValue).reduce((acc:any, value:any) => acc + Number(value), 0);
  }

  getTotalSalesPrice() {
    return this.salesRecords.map((t:any) => t.ContractSalesPrice).reduce((acc:any, value:any) => acc + Number(value), 0);
  }

  getRemainingCommission() {
    const total = this.salesRecords.map((t:any) => t.commissionValue).reduce((acc:any, value:any) => acc + Number(value), 0);
    const paid = this.commissionHistory.map((t:any) => t.amount).reduce((acc:any, value:any) => acc + Number(value), 0);
    return total - paid;
  }

  applyFilter() {
    if ($.fn.DataTable.isDataTable('#latestSalesTable')) {
      this.destroyTable();
    }
    this.loadSales();
    if (this.role !== '1') {
      this.getSalesCommissionHistory();
    }
  }

  isNA(val) {
    return val === 'N/A';
  }

  searchFilter(val) {
    const value = val ? val.trim() : '';
    this.oTable.search(value).draw();
  }

  initTable() {
    $(() => {
      this.oTable = $('#latestSalesTable').DataTable({
        'paging': true,
        'lengthChange': false,
        'searching': true,
        'ordering': false,
        'info': true,
        'autoWidth': false,
        'processing': true,
        'pageLength': 5
      });
    });
  }

  destroyTable() {
    this.oTable.clear().destroy();
  }
}
