import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ConstantService } from './constant.service';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(
    private http: HttpClient,
    private constantSvc: ConstantService,
    private helperSvc: HelperService,
    private router: Router,
    private _cookieService: CookieService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(this.helperSvc.lsGetItem(this.constantSvc.tokenConfig.CURRENT_USER));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public get isLoggedIn() {
    return this.currentUser;
  }

  login(url, data) {
    this.helperSvc.loaderStart();
    return this.http.post<any>(this.constantSvc.APIBaseURL + url, data)
      .pipe(
        map(user => {
          this.helperSvc.loaderStop();
          if (user && user.token) {
            this.helperSvc.lsSetItem(this.constantSvc.tokenConfig.CURRENT_USER, user);
            this.currentUserSubject.next(user);
          }
          return user;
        })
      );
  }

  loginAdmin(url, data) {
    // this.helperSvc.lsClear();
    // this.helperSvc.ssClear();
    // this._cookieService.delete('UserToken');
    this.currentUserSubject.next(null);
    // localStorage.setItem('logout-event', 'logout' + Math.random());
    this.helperSvc.loaderStart();
    return this.http.post<any>(this.constantSvc.APIBaseURL + url, data)
      .pipe(
        map(user => {
          this.helperSvc.loaderStop();
          if (user && user.token) {
            this.helperSvc.lsSetItem(this.constantSvc.tokenConfig.CURRENT_USER, user);
            this.currentUserSubject.next(user);
          }
          return user;
        })
      );
  }

  backToAdmin(){
    const currentUser = this.helperSvc.lsGetItem('ADMIN');
    this.helperSvc.lsSetItem(this.constantSvc.tokenConfig.CURRENT_USER, currentUser);
    this.currentUserSubject.next(currentUser);
    this.helperSvc.lsRemoveItem('ADMIN');
    this.helperSvc.lsRemoveItem('tierId');
    this.helperSvc.lsRemoveItem('myTier');
  }

  forget(url, data) {
    this.helperSvc.loaderStart();
    return this.http.post<any>(this.constantSvc.APIBaseURL + url, data)
      .pipe(map(res => {
        this.helperSvc.loaderStop();
        return res;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    this.helperSvc.lsClear();
    this.helperSvc.ssClear();
    this._cookieService.delete('UserToken');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
    localStorage.setItem('logout-event', 'logout' + Math.random());
  }


}
