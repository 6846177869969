import { Injectable } from '@angular/core';
import { AlertService } from './alert.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

declare var NProgress: any;
NProgress.configure({ showSpinner: true });

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  private history = [];
  constructor(
    private alertSvc: AlertService,
    private router: Router
  ) { }

  public loaderStart() {
    NProgress.start();
  }

  public loaderStop() {
    NProgress.done();
  }

  public lsSetItem(key, value) {
    return localStorage.setItem(key, JSON.stringify(value));
  }
  public lsGetItem(key) {
    return JSON.parse(localStorage.getItem(key));
  }
  public lsRemoveItem(key) {
    return localStorage.removeItem(key);
  }
  public lsClear() {
    return localStorage.clear();
  }

  public ssSetItem(key, value) {
    return sessionStorage.setItem(key, JSON.stringify(value));
  }

  public ssGetItem(key) {
    return JSON.parse(sessionStorage.getItem(key));
  }
  public ssRemoveItem(key) {
    return sessionStorage.removeItem(key);
  }
  public ssClear() {
    return sessionStorage.clear();
  }

  public b64EncodeUnicode(str: any) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
      (match, p1) => {
        return String.fromCharCode(('0x' + p1) as any);
      }));
  }

  public b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  public errorHandler(error) {
    switch (error.status) {
      case 400:
        this.alertSvc.error(error.statusText);
        break;
      case 401:
        this.alertSvc.error(error.statusText);
        break;
      case 402:
        this.alertSvc.error(error.statusText);
        break;
      case 403:
        this.alertSvc.error(error.statusText);
        break;
      case 404:
        this.alertSvc.error(error.statusText);
        break;
      default:
        break;
    }
  }

  public loadRouting(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        // this.history = [...this.history, urlAfterRedirects];
        this.history = [this.history, urlAfterRedirects];
      });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/index';
  }

  public getMonths(): any {
    const monthsArray: any = [
      { 'key': 'January', 'value': '01' },
      { 'key': 'February', 'value': '02' },
      { 'key': 'March', 'value': '03' },
      { 'key': 'April', 'value': '04' },
      { 'key': 'May', 'value': '05' },
      { 'key': 'June', 'value': '06' },
      { 'key': 'July', 'value': '07' },
      { 'key': 'August', 'value': '08' },
      { 'key': 'September', 'value': '09' },
      { 'key': 'October', 'value': '10' },
      { 'key': 'November', 'value': '11' },
      { 'key': 'December', 'value': '12' },
    ];
    return monthsArray;
  }

  public getYears(): any {
    let startYear = 2019;
    const date = new Date();
    const currentYear = date.getFullYear();
    let finished = false;
    const yearsArray: any = [];
    while (!finished) {
      if (currentYear < startYear) {
        finished = true;
        break;
      }
      yearsArray.push(startYear);
      startYear++;
    }
    return yearsArray;
  }
}
