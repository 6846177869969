import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantService {
  // public APIBaseURL = 'http://localhost/commission-portal/commissionapi/index.php/api/';
  //public APIBaseURL = 'http://192.168.1.109/commission-portal/api/';
  public APIBaseURL = 'https://commission.houstondirectauto.com/commission-portal/api/';

  public APIConfig = {
    FINANCE_USERS: 'usersCtl/financeUsers',
    USERSBYROLE: 'usersCtl/userByRole',
    SALES_NEW: 'sales/getSalesNew',
    SALES: 'sales/getSales',
    GETLATESTSALES: 'sales/getLatestSales',
    GETLATESTBACKENDSALES:'sales/getLatestBackendSales',
    COUNTSALES: 'sales/countSales',
    COUNTCURRENTMONTHSALES: 'sales/countCurrentMonthSales',
    COUNTTEAMEMBERS: 'usersCtl/countTeam',
    SALESMANOFMONTH: 'sales/salesmanOfMonth',
    GETSALESBYTEAMMEMBERS: 'sales/getSalesByTeamMembers',
    USERS: 'usersCtl/users',
    ROLESLIST: 'usersCtl/roles',
    GETUSER: 'usersCtl/user',
    GETUSERBYIDMS: 'usersCtl/getUserByIDMS',
    UPDATEUSER: 'usersCtl/user',
    DELETEUSER: 'usersCtl/user/',
    RESETPASSWORD: 'usersCtl/resetPassword',
    UPDATEVENDORID: 'usersCtl/updateVendor',
    SENDREQUEST: 'sales/sendRequest',
    GETTIEROFMONTH: 'sales/getTierOfMonth',
    GETUSERTIERS: 'sales/getUserTiers',
    // GETCURRENTMONTHTIERS: 'sales/getCurrentMonthTiers',
    GETCOMMISSIONS: 'sales/getCommissions',
    EXPORTCOMMISSIONHISTORY: 'sales/exportCommissionHistory',
    EXPORTBACKENDUSERCOMMISSIONHISTORY: 'sales/exportBackendUserCommissionHistory',
    EXPORTSALESUSERCOMMISSIONHISTORY: 'sales/exportSalesUserCommissionHistory',
    EXPORTSALESFORINTECT: 'sales/exportSalesForIntect',
    EXPORTSALESFORINTACTBACKEND: 'sales/exportSalesForIntactBackend',
    GETCOMMISSION: 'sales/getCommission',
    GETBACKENDCOMMISSION:'sales/getBackendCommission',
    GETPREVIOUSMONTHSREMAININGCOMM: 'sales/getPreviousMonthsRemainingComm',
    GETPREVIOUSMONTHSREMAININGBACKENDCOMM: 'sales/getPreviousMonthsRemainingBackendComm',
    GETCOMMISSIONHISTORY: 'sales/getCommissionHistory',
    GETBACKENDCOMMISSIONHISTORY: 'sales/getBackendCommissionHistory',
    ADDCOMMISSION: 'sales/addCommission',
    ADDBACKENDCOMMISSION:'sales/addBackendCommission',
    COUNTALLUSERS: 'usersCtl/countAll',
    GETREQUESTS: 'sales/getRequests', // publicApi/getRequests
    GETNOTIFICATIONS: 'sales/getNotifications', // publicApi/getNotifications
    GETREQUESTUPDATENOTIFICATION: 'sales/getRequestUpdateNotification', // publicApi/getRequestUpdateNotification
    GETBUYERNOTIFICATIONS: 'buyers/getNotifications', // publicApi/getBuyersNotifications
    GETCOMMISSIONADJUSTINFO: 'sales/getCommissionAdjustInfo',
    UPDATENOTIFICATIONSTATUS: 'sales/updateNotification',
    GETNOTIFICATIONSBYDEAL: 'sales/getNotificationsByDeal',
    REQUESTREPLY: 'sales/updateRequest',
    REQUESTDECLINE: 'sales/requestDecline',
    UPDATEREQUESTSEENSTATUS: 'sales/updateRequestSeenStatus',
    CALCULATECOMMISSION: 'sales/calculate_commission',
    UPDATECOMMISSIONAMOUNT: 'sales/updateCommissionAmount',
    UPDATEBACKENDCOMMISSIONAMOUNT: 'sales/updateBackendCommissionAmount',
    UPDATEELEGIBLITY: 'sales/updateCommissionEligiblity',
    UPDATECOMMISSIONPAIDSTATUS: 'sales/updateCommissionPaidStatus',
    UPDATEBACKENDCOMMISSIONPAIDSTATUS: 'sales/updateBackendCommissionPaidStatus',
    COMMISSIONMARKEDPAIDMULTIPLE: 'sales/commissionMarkedPaidMultiple',
    COMMISSIONRUNDEALMULTIPLE: 'sales/commissionRunDealMultiple',
    COMMISSIONMARKEDUNPAIDMULTIPLE: 'sales/commissionMarkedUnpaidMultiple',
    GETDEALDATA: 'sales/sale',
    ADDDEALTOWATCH: 'sales/addDealToWatch',
    REMOVEDEALTOWATCH: 'sales/removeDealFromWatch',
    ADDCOMMENTS: 'sales/comments',
    GETCOMMENTS: 'sales/comments',
    GETRECENTCOMMENT: 'sales/getRecentComment',
    REMOVECOMMENTFLAG: 'sales/removeCommentFlag',
    GET_COMM_EXCHANGE_HISTORY: 'sales/getCommExchangeHistory',
    GETSTOCKLIST:'sales/getStockList',

    COMMRULES: 'rules/commRules',
    BACKENDRULES: 'rules/backEndCommRules',
    ADJUSTRULES: 'rules/commAdjustRules',
    ADDADJUSTRULE: 'rules/addCommAdjustRule',
    EDITADJUSTRULE: 'rules/editCommAdjustRule',
    DELETECOMMRULE: 'rules/deleteCommRule/',
    DELETEBACKENDRULE: 'rules/deleteBackendRule/',
    GETSELECTEDIDMSFIELDS: 'sales/getSelectedIDMSFields',
    ADDCOMMRULES: 'rules/addCommRules',
    ADDBACKENDRULES: 'rules/addBackendRules',
    GETRULE: 'rules/commRule',
    GETBACKENDRULE: 'rules/backendRule',
    UPDATECOMMRULE: 'rules/CommRule',
    UPDATEBACKENDRULE: 'rules/BackendRule',
    GETSETTINGS: 'auth/settings',
    UPDATESETTINGS: 'auth/settings/',
    GETSALESMANAGERCOMMISSION: 'auth/getSalesManagerCommission',
    UPDATESALESMANAGERCOMMISSION: 'auth/updateSalesManagerCommission',
    GETSMSTEMPLATE: 'auth/getSmsTemplate',
    UPDATESMSTEMPLATE: 'auth/updateSmsTemplate',
    GETSALESTEMPLATE: 'auth/getSalesTemplate',
    UPDATESALESTEMPLATE: 'auth/updateSalesTemplate',
    GETFLAGSLIST: 'auth/getFlagsList',
    ADDFLAG: 'auth/addFlag',
    EDITFLAG: 'auth/editFlag',
    DELETEFLAG: 'auth/deleteFlag/',
    GETFLAGBYID: 'auth/flag/',
    TIERSLIST: 'tiers/tiers',
    ADDTIER: 'tiers/tiers',
    UPDATETIER: 'tiers/tier',
    TIERS: 'tiers/tiersWithFilter',
    FINANCE_DEPT_TIERS: 'tiers/financeTier', // new implementation
    ADD_FINANCE_DEPT_TIER: 'tiers/addFinanceTier',
    UPDATE_FINANCE_DEPT_TIER: 'tiers/updateFinanceTier',
    DELETE_FINANCE_DEPT_TIER: 'tiers/deleteFinanceTier/',
    DELETETIER: 'tiers/tier/',
    GETMRFEELIST: 'sales/mrFees',
    ADDMRFEE: 'sales/mrFee',
    EDITMRFEE: 'sales/mrFee',
    DELETEMRFEE: 'sales/mrFee/',
    GETFORMULALIST: 'sales/formulas',
    ADDFORMULA: 'sales/formula',
    EDITFORMULA: 'sales/formula',
    SEARCHUSER: 'usersCtl/search',
    DELETEMULTIPLECOMMRULES: 'rules/deleteMultipleCommRule/',
    GETALLIDMSFIELDS: 'sales/getIDMSFields',
    SAVEIDMSFIELDS: 'sales/saveIDMSDataFields',
    ADDUSER: 'usersCtl/users',
    ISUNIQEMAIL: 'usersCtl/isEmailRegisterd',
    ISUNIQEID: 'usersCtl/isIDRegisterd',
    SAVEIDMSTEMPLATE: 'sales/saveIDMSTemplate',
    GETLENDERSLIST: 'sales/getLendersList',
    TESTFORMULA: 'sales/testFormula',
    GETCRONLIST: 'sales/crons',
    SAVECRON: 'sales/cron',
    UPDATECRON: 'sales/cron',
    DELETECRON: 'sales/cron/',
    RUNCRON: 'sales/runCronManually',
    GETPODIUMSTATS: 'sales/getPodiumStats',
    EXPORTBACKENDCOMMISSIONREPORT:'sales/exportBackendCommission',

    // Phase 2
    GETALLBUYERS: 'buyers/buyers',
    GETBUYERBYIDMS: 'buyers/getBuyerByIDMS',
    COUNTBUYERS: 'buyers/count',
    GETBUYER: 'buyers/buyer',
    ADDBUYER: 'buyers/buyer',
    EDITBUYER: 'buyers/buyer',
    DELETEBUYER: 'buyers/buyer/',
    ISBUYERUNIQEMAIL: 'buyers/isEmailRegisterd',
    ISBUYERUNIQEID: 'buyers/isIDRegisterd',
    UPDATEBUYERVENDORID: 'buyers/updateVendor',
    VEHICLES: 'buyers/vehicles',
    GETVEHICLEBYSTOCK: 'buyers/vehicles',
    ADDBUERSTIER: 'buyers/tier',
    EDITBUYERTIER: 'buyers/tier',
    DELETEBUYERTIER: 'buyers/tier/',
    GETBUYERSTIERS: 'buyers/tiers',
    ADDCURTAILMENT: 'buyers/curtailment',
    EDITCURTAILMENT: 'buyers/curtailment',
    DELETECURTAILMENT: 'buyers/curtailment/',
    GETCURTAILMENTS: 'buyers/curtailments',
    BUYERSFLOORCOSTFORMULAS: 'buyers/floorCostFormulas',
    ADDFLOORCOSTFORMULA: 'buyers/floorCostFormula',
    EDITFLOORCOSTFORMULA: 'buyers/floorCostFormula',
    DELETEFLOORCOSTFORMULA: 'buyers/floorCostFormula/',
    BUYERSINSURANCECOSTFORMULAS: 'buyers/insuranceCostFormulas',
    ADDINSURANCECOSTFORMULA: 'buyers/insuranceCostFormula',
    EDITINSURANCECOSTFORMULA: 'buyers/insuranceCostFormula',
    DELETEINSURANCECOSTFORMULA: 'buyers/insuranceCostFormula/',
    BUYERSCOMMISSIONFORMULAS: 'buyers/commissionFormulas',
    ADDBUYERCOMMISSIONFORMULA: 'buyers/commissionFormula',
    EDITBUYERCOMMISSIONFORMULA: 'buyers/commissionFormula',
    DELETEBUYERCOMMISSIONFORMULA: 'buyers/commissionFormula/',
    GETBUYERCOMMISSIONFORMULA: 'buyers/commissionFormula',
    UPDATEBUYERCOMMISSIONELEGIBLITY: 'buyers/updateCommissionEligiblity',
    UPDATEBUYERCOMMISSIONPAIDSTATUS: 'buyers/updateCommissionPaidStatus',
    UPDATEBUYERCOMMISSIONAMOUNT: 'buyers/updateCommissionAmount',
    EXPORTBUYERSALESFORINTECT: 'buyers/exportSalesForIntect',
    LOADSALESBYBUYERS: 'buyers/loadSalesByBuyers',
    BUYERCOUNTSALES: 'buyers/countSales',
    BUYEROFMONTH: 'buyers/buyerOfMonth',
    BUYERSLATESTSALES: 'buyers/getLatestSales',
    GETBUYERSCOMMISSIONS: 'buyers/getBuyersCommissions',
    GETBUYERSCOMMISSIONHISTORY: 'buyers/getBuyersCommissionHistory',
    ADDBUYERCOMMISSION: 'buyers/addBuyerCommission',
    EXPORTBUYERSCOMMISSION: 'buyers/exportBuyersCommission',
    GETCOMMENTSBYSTOCK: 'buyers/commentsByStock',
    ADDCOMMENTSBYSTOCK: 'buyers/commentsByStock',
    GETRECENTCOMMENTBYSTOCK: 'buyers/getRecentCommentByStock',
    REMOVECOMMENTFLAGBYSTOCK: 'buyers/removeCommentFlagByStock',
    UPDATEBUYERNOTIFICATIONSTATUS: 'buyers/updateNotification',
    GETBUYERTIERS: 'buyers/getBuyerTiers',
    GETBUYERSCOMMISSION: 'buyers/getBuyersCommission',
    COUNTMONTHLYSALES: 'buyers/countMonthlySales',
    GETBUYERSFORMULALIST: 'buyers/formulas',
    UPDATEBUYERSFORMULA: 'buyers/formula',
    ADDBUYERPROFITFORMULA: 'buyers/formula',
    BUYERSCOMMISSIONMARKEDPAIDMULTIPLE: 'buyers/commissionMarkedPaidMultiple',
    BUYERSCOMMISSIONMARKEDUNPAIDMULTIPLE: 'buyers/commissionMarkedUnpaidMultiple',
    GETINSPECTIONREPORT: 'inspectors/getReports',
    GETINSPECTORSLIST: 'usersCtl/inspectors',
    GETINSPECTORCOMMISSIONFORMULA: 'rules/getInspectionCommissionRule',
    ADDINSPECTORCOMMISSIONFORMULA: 'rules/addInspectionCommissionRules',
    GETINSPECTORCOMMISSIONFORMULAS: 'rules/getInspectionCommissionRules',
    DELETEINSPECTORCOMMISSIONFORMULA: 'rules/deleteInspectionCommissionRule/',
    UPDATEINSPECTORCOMMISSIONFORMULA: 'rules/updateInspectionCommissionRule',
    UPDATEINSPECTORCOMMISSIONELEGIBLITY: 'inspectors/updateCommissionElegiblity',
    UPDATEINSPECTORCOMMISSIONPAIDSTATUS: 'inspectors/updateCommissionPaidStatus',
    INSPECTORCOMMISSIONMARKEDPAIDMULTIPLE: 'inspectors/commissionMarkedPaidMultiple',
    INSPECTORCOMMISSIONMARKEDUNPAIDMULTIPLE: 'inspectors/commissionMarkedUnpaidMultiple',
    GETINSPECTORSCOMMISSIONS: 'inspectors/getCommissions',
    GETINSPECTORSCOMMISSIONPAIDHISTORY: 'inspectors/getCommissionPaidHistory',
    ADDINSPECTORCOMMISSION: 'inspectors/addCommission',
    COUNTINSPECTEDVAECHILESBYUSER: 'inspectors/countInspectedVehiclesByUser',
    COUNTTOTALCARINSPECTED: 'inspectors/countTotalCarInspected',
    GETLATESTINSPECTIONREPORT: 'inspectors/getLatestInspectionReport',
    GETCOMMISSIONHISTORYBYINSPECTOR: 'inspectors/getCommission',
    GETINSPECTORPREMONTHCOMM: 'inspectors/getPreviousMonthsRemainingComm',
    GETAVGINVENTORY: 'sales/getAvgInventory',
    GETHDAINVENTORY: 'sales/getHDAInventory',
    GETAPISETTINGSDATA: 'auth/getApiSettings',
    UPDATEAPISETTINGSDATA: 'auth/updateApiSettingsData',
    UPDATEACCTTYPE: 'sales/updateAcctType',
    UPDATENETCHECK:'sales/updateNetCheck',

    UPDATEVAUTOPRICE: 'sales/updatevAutoPrice',
    UPDATEFRONTENDPROFIT: 'sales/updateFrontendProfit',
    UPDATEBACKENDPROFIT: 'sales/updateBackendProfit',


    // in auth class
    LOGIN: 'auth/login',
    FORGET: 'auth/forget',
    UPDATEPROFILE: 'auth/profile',
    UPDATEPROFILEPIC: 'auth/updateProfilePic',
    UPDATEPASSWORD: 'auth/updatePassword',

    //sneha
    ADDCASHADVANCE: 'sales/addCashAdvance',
    GETCASHADVANCEHISTORY:'sales/getCashAdvanceHistory',

    EMAILCASHADVANCE:'sales/emailCashAdvanceUsers',
    REQUESTTOUPDATEVAUTOPRICE : 'sales/requestToUpdateAutoPrice',

    GETAUTOPRICEREQUEST : 'sales/autoPriceRequests',
    DELETEAUTOPRICEREQUEST : 'sales/autoPriceRequest/',
    UPDATEAUTOPRICEREQUEST : 'sales/autoPriceRequest',

    GETUSERLIST: 'usersCtl/userList',

    GETUSERLISTADMIN:'auth/users',
    ADMINUSERLOGIN:'auth/admin_login'
  };

  public tokenConfig = {
    AUTH_TOKEN: 'AUTHTOKEN',
    USER_INFO: 'USERINFO',
    USER_ROLES: 'userRoles',
    VERIFY_DATA: 'VERIFY',
    CURRENT_USER: 'CURRENTUSER',
  };

  public dashboardConfig = {
    DASHBOARD_URL: 'DASHBOARD',
    DASHBOARD_USER_iDMSID: 'DASHBOARD_USER_iDMS_ID',
    DASHBOARD_USER_ROLE: 'DASHBOARD_USER_ROLE'
  };

  public errMsgConfig = {
    UNKNOWN_ERROR: 'Some Error Occured.'
  };

}
