import { Component } from '@angular/core';
import { HelperService } from './core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private HelperSvc: HelperService) {
    this.HelperSvc.loadRouting();

    window.addEventListener('storage', function (event) {
      if (event.key === 'logout-event') {
        this.window.location.reload();
      }
    });
  }
}
