import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

export class InspectionReportDataSource implements DataSource<any> {

    private reportSubject = new BehaviorSubject<any[]>([]);
    public data$;

    // to show loading
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();

    // to show the total number of records
    private countSubject = new BehaviorSubject<number>(0);
    public counter$ = this.countSubject.asObservable();

    constructor(private apiSvc) { }

    loadRecords(filter: string, sort: string, sortDirection: string, pageIndex: number, pageSize: number, inspector: string, stock: string, vin: string, eligible: string, commissionPaid: string, inspection: string, isIndividual: boolean, userName: string) {
        this.loadingSubject.next(true);

        this.apiSvc.findInspectionReport(filter, sort, sortDirection, pageIndex, pageSize, inspector, stock, vin, eligible, commissionPaid, inspection, isIndividual, userName)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(result => {
                this.data$ = result['Data'];
                this.reportSubject.next(result['Data']);
                this.countSubject.next(result['RecordsTotal']);
            });
    }

    connect(collectionViewer: CollectionViewer): Observable<any[]> {
        return this.reportSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.reportSubject.complete();
        this.loadingSubject.complete();
        this.countSubject.complete();
    }
}
